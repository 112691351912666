import EditIcon from '@mui/icons-material/Edit';
import { DialogActions } from '@mui/joy';
import Button from '@mui/joy/Button';
import DialogContent from '@mui/joy/DialogContent';
import DialogTitle from '@mui/joy/DialogTitle';
import IconButton from '@mui/joy/IconButton';
import Modal from '@mui/joy/Modal';
import ModalDialog from '@mui/joy/ModalDialog';
import Option from '@mui/joy/Option';
import Select from '@mui/joy/Select';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import { closeSnackbar, enqueueSnackbar } from 'notistack';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { saveMetaDossier } from 'cloudFunctions/functions.ts';
import FullScreenLoader from 'components/UI/FullScreenLoader.tsx';
import i18n from 'i18n.ts';
import { queryClient } from 'queries';
import { useAppState } from 'stores/appStore.ts';
import { useChantierState } from 'stores/chantierStore.ts';
import { REVIEW_PHASES_ORDERED, TDossierPhase } from 'types/index';

export default function EditPhaseBtn() {
  const [isSaving, setIsSaving] = useState(false);
  const [openEditPhaseModal, setOpenEditPhaseModal] = useState(false);
  const urlParams = useAppState((state) => state.urlParams);
  const { t } = useTranslation();
  const metaDossierForChantier = useChantierState(
    (state) => state.metaDossierForChantier
  );
  const remotePhase = metaDossierForChantier?.dossierPhase;
  const [newPhase, setNewPhase] = useState(remotePhase);

  const phasesInfo: {
    value: TDossierPhase;
    label: string;
    isSelectable: boolean;
  }[] = useMemo(() => {
    // @ts-ignore
    const remotePhaseIdx = REVIEW_PHASES_ORDERED.indexOf(remotePhase);
    return REVIEW_PHASES_ORDERED.map((phase: TDossierPhase, idx: number) => ({
      label: t(`review.phase.${phase}`),
      value: phase,
      isSelectable: remotePhaseIdx === -1 || idx <= remotePhaseIdx,
    }));
  }, [remotePhase, t]);

  useEffect(() => {
    setNewPhase(remotePhase);
  }, [openEditPhaseModal, remotePhase]);

  const handleSave = useCallback(async () => {
    if (!newPhase || newPhase === remotePhase) {
      setOpenEditPhaseModal(false);
      return;
    }

    const notification = enqueueSnackbar(
      i18n.t('sync.notifications.save.inProgress'),
      {
        variant: 'info',
        autoHideDuration: null,
      }
    );
    setIsSaving(true);
    try {
      await saveMetaDossier({
        retrieveParams: {
          mode: 'fromChantierId',
          chantierRowId: urlParams.chantierId!,
        },
        moveToReviewPhase: newPhase,
      });
      await queryClient.invalidateQueries({
        queryKey: ['chantier', 'meta'],
      });
      enqueueSnackbar(i18n.t('sync.notifications.save.success'), {
        variant: 'success',
      });
      setOpenEditPhaseModal(false);
    } catch (e) {
      console.error(e);
      enqueueSnackbar(i18n.t('sync.notifications.save.failed'), {
        variant: 'error',
      });
    } finally {
      closeSnackbar(notification);
      setIsSaving(false);
    }
  }, [newPhase, remotePhase, urlParams.chantierId]);

  return (
    <>
      {isSaving && <FullScreenLoader />}
      <IconButton onClick={() => setOpenEditPhaseModal(true)}>
        <EditIcon />
      </IconButton>
      <Modal
        open={openEditPhaseModal}
        onClose={() => {
          setOpenEditPhaseModal(false);
        }}
        keepMounted={false}
      >
        <ModalDialog>
          <DialogTitle>Éditer la phase du dossier</DialogTitle>
          <DialogContent>
            <Stack direction={'column'} gap={1}>
              <Typography>
                Phase actuelle : {t(`review.phase.${remotePhase}`)}
              </Typography>

              <Select
                value={newPhase}
                onChange={(_, selectedPhase) => setNewPhase(selectedPhase)}
              >
                {phasesInfo.map((phase) => (
                  <Option
                    key={phase.value}
                    disabled={!phase.isSelectable}
                    value={phase.value}
                  >
                    {phase.label}
                  </Option>
                ))}
              </Select>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack direction={'row'} gap={1}>
              <Button
                onClick={() => setOpenEditPhaseModal(false)}
                variant={'outlined'}
              >
                Annuler
              </Button>
              <Button
                onClick={handleSave}
                variant={'soft'}
                disabled={newPhase === remotePhase}
              >
                Enregistrer
              </Button>
            </Stack>
          </DialogActions>
        </ModalDialog>
      </Modal>
    </>
  );
}
