export const glideDocTypesRawTable = [
  {
    rowId: 'F14H0wDZTn6Xe9IIwKL9-Q',
    label: 'Accord Artisan',
    dbDocumentId: 'accord_artisan',
    owners: [],
    reviewPhase: null,
    reviewPhaseOrder: null,
    requiredForCodesAdeme: [],
  },
  {
    rowId: 'YVy1kIv0S7GajQRyviFLMQ',
    label: 'Agrément Anah',
    dbDocumentId: 'agrement_anah',
    owners: ['ops'],
    reviewPhase: null,
    reviewPhaseOrder: null,
    requiredForCodesAdeme: [],
  },
  {
    rowId: 'aR5i24HFQKuLhqGOZ8ivxA',
    label: 'Attestation de changement de matériel',
    dbDocumentId: 'attestation_changement_materiel',
    owners: ['artisan'],
    reviewPhase: null,
    reviewPhaseOrder: null,
    requiredForCodesAdeme: [],
  },
  {
    rowId: 'oYCvuPR7QvyQIIM62oo4Vw',
    label: 'Attestation de consentement Anah non signée',
    dbDocumentId: 'attestation_consentement',
    owners: [],
    reviewPhase: null,
    reviewPhaseOrder: null,
    requiredForCodesAdeme: [],
  },
  {
    rowId: 'aT3408KhTemcUJ1uN3xdew',
    label: 'Attestation de propriété',
    dbDocumentId: 'attestation_propriete',
    owners: ['beneficiaire'],
    reviewPhase: null,
    reviewPhaseOrder: null,
    requiredForCodesAdeme: [],
  },
  {
    rowId: 'uRB9pxZxQWu6a5u3JUcsJw',
    label: 'Attestation de régularité Fiscale',
    dbDocumentId: 'attestation_regularite_fiscale',
    owners: ['artisan'],
    reviewPhase: null,
    reviewPhaseOrder: null,
    requiredForCodesAdeme: [],
  },
  {
    rowId: 'aoc2M-vFTQ-b.HWNtaD-1A',
    label: 'Attestation de régularité URSSAF',
    dbDocumentId: 'attestation_urssaf',
    owners: ['artisan'],
    reviewPhase: null,
    reviewPhaseOrder: null,
    requiredForCodesAdeme: [],
  },
  {
    rowId: 'AQHhYu2hTa6joG5Umjlbtg',
    label: "Attestation sur l'honneur (signé Artisan)",
    dbDocumentId: 'attestation_sur_honneur',
    owners: [],
    reviewPhase: null,
    reviewPhaseOrder: null,
    requiredForCodesAdeme: [],
  },
  {
    rowId: 's4IL1zGuTLKKYWSzsOgPAQ',
    label: "Attestation sur l'honneur générée",
    dbDocumentId: 'attestation_sur_honneur',
    owners: [],
    reviewPhase: null,
    reviewPhaseOrder: null,
    requiredForCodesAdeme: [
      'BAR-EN-101',
      'BAR-EN-102',
      'BAR-EN-103',
      'BAR-TH-112',
      'BAR-TH-113',
      'BAR-TH-129',
      'BAR-TH-143',
      'BAR-TH-148',
      'BAR-TH-159',
      'BAR-TH-171',
      'BAR-TH-172',
    ],
  },
  {
    rowId: 'v69cGZj5S9Kr2iysP9pfSA',
    label: "Attestation sur l'honneur Signée",
    dbDocumentId: 'attestation_sur_honneur',
    owners: ['artisan', 'beneficiaire'],
    reviewPhase: 'lastdepot_ke',
    reviewPhaseOrder: 10,
    requiredForCodesAdeme: [
      'BAR-EN-101',
      'BAR-EN-102',
      'BAR-EN-103',
      'BAR-TH-112',
      'BAR-TH-113',
      'BAR-TH-129',
      'BAR-TH-143',
      'BAR-TH-148',
      'BAR-TH-159',
      'BAR-TH-171',
      'BAR-TH-172',
    ],
  },
  {
    rowId: 'Qm9ZpymeTFKiyvo8C---hg',
    label: 'Autre',
    dbDocumentId: 'autre',
    owners: ['artisan', 'beneficiaire'],
    reviewPhase: null,
    reviewPhaseOrder: null,
    requiredForCodesAdeme: [],
  },
  {
    rowId: 'c.Gaxd-VTx-HHQyOj4nkLQ',
    label: "Avis d'imposition client (N-1)",
    dbDocumentId: 'avis_imposition',
    owners: ['beneficiaire'],
    reviewPhase: 'debut_chantier',
    reviewPhaseOrder: 1,
    requiredForCodesAdeme: [
      'BAR-EN-101',
      'BAR-EN-102',
      'BAR-EN-103',
      'BAR-TH-112',
      'BAR-TH-113',
      'BAR-TH-143',
      'BAR-TH-148',
      'BAR-TH-159',
      'BAR-TH-171',
      'BAR-TH-172',
    ],
  },
  {
    rowId: 'GTIHDuHmSH6KOfXgWNsKbw',
    label: "Avis d'imposition client (N-2)",
    dbDocumentId: 'avis_imposition',
    owners: ['beneficiaire'],
    reviewPhase: 'debut_chantier',
    reviewPhaseOrder: 2,
    requiredForCodesAdeme: [
      'BAR-EN-101',
      'BAR-EN-102',
      'BAR-EN-103',
      'BAR-TH-112',
      'BAR-TH-113',
      'BAR-TH-143',
      'BAR-TH-148',
      'BAR-TH-159',
      'BAR-TH-171',
      'BAR-TH-172',
    ],
  },
  {
    rowId: 'a.89J6zu2TWaYYmvkZaRmGA',
    label: 'Cadre de contribution non signé',
    dbDocumentId: 'cadre_contribution',
    owners: [],
    reviewPhase: null,
    reviewPhaseOrder: null,
    requiredForCodesAdeme: [
      'BAR-EN-101',
      'BAR-EN-102',
      'BAR-EN-103',
      'BAR-TH-112',
      'BAR-TH-113',
      'BAR-TH-129',
      'BAR-TH-143',
      'BAR-TH-148',
      'BAR-TH-159',
      'BAR-TH-171',
      'BAR-TH-172',
    ],
  },
  {
    rowId: 'hg1lYLYfTNKubwZ3GKk8Og',
    label: 'Cadre de contribution signé',
    dbDocumentId: 'cadre_contribution',
    owners: ['ops'],
    reviewPhase: 'firstdepot_ke',
    reviewPhaseOrder: 6,
    requiredForCodesAdeme: [
      'BAR-EN-101',
      'BAR-EN-102',
      'BAR-EN-103',
      'BAR-TH-112',
      'BAR-TH-113',
      'BAR-TH-129',
      'BAR-TH-143',
      'BAR-TH-148',
      'BAR-TH-159',
      'BAR-TH-171',
      'BAR-TH-172',
    ],
  },
  {
    rowId: 'xdETITpCQiqbaOuJ7GTgig',
    label: "Carte nationale d'identité",
    dbDocumentId: '',
    owners: [],
    reviewPhase: null,
    reviewPhaseOrder: null,
    requiredForCodesAdeme: [],
  },
  {
    rowId: 'zU2LiBGCQSeqfJggdajI.Q',
    label: 'Cerfa MaPrimeRénov signé',
    dbDocumentId: 'cerfa_mpr',
    owners: ['beneficiaire'],
    reviewPhase: null,
    reviewPhaseOrder: null,
    requiredForCodesAdeme: [],
  },
  {
    rowId: '3kTqKMhsQdWCZan5zfKN3A',
    label: 'Cerfa MPR à signer',
    dbDocumentId: 'cerfa_mpr_unsigned',
    owners: [],
    reviewPhase: null,
    reviewPhaseOrder: null,
    requiredForCodesAdeme: [],
  },
  {
    rowId: 'tVwX.iujQTWqVaMxjxZQDA',
    label: 'Certificat Acermi',
    dbDocumentId: 'certificat_acermi',
    owners: ['artisan'],
    reviewPhase: 'debut_chantier',
    reviewPhaseOrder: 4.4,
    requiredForCodesAdeme: ['BAR-EN-101', 'BAR-EN-102', 'BAR-EN-103'],
  },
  {
    rowId: 'gEXSqlN.TJS7zs2pLgccdA',
    label: 'Certificat capteurs solaires',
    dbDocumentId: 'certificat_capteur_solaires',
    owners: ['artisan'],
    reviewPhase: 'debut_chantier',
    reviewPhaseOrder: 4.3,
    requiredForCodesAdeme: ['BAR-TH-143'],
  },
  {
    rowId: 'BVCS2JAoSDSPBnjfSur7kw',
    label: 'Certificat RAI',
    dbDocumentId: 'certificat_rai',
    owners: [],
    reviewPhase: 'firstdepot_ke',
    reviewPhaseOrder: null,
    requiredForCodesAdeme: [
      'BAR-EN-101',
      'BAR-EN-102',
      'BAR-EN-103',
      'BAR-TH-112',
      'BAR-TH-113',
      'BAR-TH-129',
      'BAR-TH-143',
      'BAR-TH-148',
      'BAR-TH-159',
      'BAR-TH-171',
      'BAR-TH-172',
    ],
  },
  {
    rowId: '6xgbhSsuSgeyzS7puopI1g',
    label: 'Certification RGE',
    dbDocumentId: 'attestation_rge',
    owners: ['ops'],
    reviewPhase: 'debut_chantier',
    reviewPhaseOrder: 3,
    requiredForCodesAdeme: [
      'BAR-EN-101',
      'BAR-EN-102',
      'BAR-EN-103',
      'BAR-TH-112',
      'BAR-TH-113',
      'BAR-TH-129',
      'BAR-TH-143',
      'BAR-TH-148',
      'BAR-TH-159',
      'BAR-TH-171',
      'BAR-TH-172',
    ],
  },
  {
    rowId: '3U1hNXJgRC6FpXtqdXc-GQ',
    label: 'Devis non-signé',
    dbDocumentId: 'devis_ns',
    owners: ['artisan'],
    reviewPhase: 'debut_chantier',
    reviewPhaseOrder: 5,
    requiredForCodesAdeme: [
      'BAR-EN-101',
      'BAR-EN-102',
      'BAR-EN-103',
      'BAR-TH-112',
      'BAR-TH-113',
      'BAR-TH-129',
      'BAR-TH-143',
      'BAR-TH-148',
      'BAR-TH-159',
      'BAR-TH-171',
      'BAR-TH-172',
    ],
  },
  {
    rowId: 'Lxf1N3EmSt2Cl7uf-df2FA',
    label: 'Devis signé',
    dbDocumentId: 'devis',
    owners: ['artisan', 'beneficiaire'],
    reviewPhase: 'firstdepot_ke',
    reviewPhaseOrder: 7,
    requiredForCodesAdeme: [
      'BAR-EN-101',
      'BAR-EN-102',
      'BAR-EN-103',
      'BAR-TH-112',
      'BAR-TH-113',
      'BAR-TH-129',
      'BAR-TH-143',
      'BAR-TH-148',
      'BAR-TH-159',
      'BAR-TH-171',
      'BAR-TH-172',
    ],
  },
  {
    rowId: 'dEF61FCMQcKQLTGeLjjuKg',
    label: 'Facture',
    dbDocumentId: 'facture',
    owners: ['artisan'],
    reviewPhase: 'fin_chantier',
    reviewPhaseOrder: 8,
    requiredForCodesAdeme: [
      'BAR-EN-101',
      'BAR-EN-102',
      'BAR-EN-103',
      'BAR-TH-112',
      'BAR-TH-113',
      'BAR-TH-129',
      'BAR-TH-143',
      'BAR-TH-148',
      'BAR-TH-159',
      'BAR-TH-171',
      'BAR-TH-172',
    ],
  },
  {
    rowId: 'UK4EZ3spRnOb23Ex62BpZw',
    label: 'Fiche Produit',
    dbDocumentId: 'fiche_technique',
    owners: ['artisan'],
    reviewPhase: 'debut_chantier',
    reviewPhaseOrder: 4.2,
    requiredForCodesAdeme: [
      'BAR-TH-112',
      'BAR-TH-113',
      'BAR-TH-129',
      'BAR-TH-143',
      'BAR-TH-148',
      'BAR-TH-159',
      'BAR-TH-171',
      'BAR-TH-172',
    ],
  },
  {
    rowId: 'Fv4ypXQHTHC73m53YVjjZg',
    label: 'Kbis (moins de 3 mois)',
    dbDocumentId: 'kbis',
    owners: ['artisan'],
    reviewPhase: null,
    reviewPhaseOrder: null,
    requiredForCodesAdeme: [],
  },
  {
    rowId: 'hZqfU-bnQfm1VTjQ.qvcXg',
    label: 'Mandat Abrico MaPrimeRénov à signer',
    dbDocumentId: 'mandat_mpr_unsigned',
    owners: [],
    reviewPhase: null,
    reviewPhaseOrder: null,
    requiredForCodesAdeme: [],
  },
  {
    rowId: 'cqmIb8A8RkO2wAxiH1--uQ',
    label: 'Mandat Abrico MaPrimeRénov signé',
    dbDocumentId: 'mandat_mpr',
    owners: ['beneficiaire'],
    reviewPhase: null,
    reviewPhaseOrder: null,
    requiredForCodesAdeme: [],
  },
  {
    rowId: 'Oorv5BN9SbWmX-drDlX9EQ',
    label: 'Note de dimensionnement',
    dbDocumentId: 'note_dimensionnement',
    owners: ['artisan'],
    reviewPhase: 'debut_chantier',
    reviewPhaseOrder: 4.1,
    requiredForCodesAdeme: [
      'BAR-TH-113',
      'BAR-TH-159',
      'BAR-TH-171',
      'BAR-TH-172',
    ],
  },
  {
    rowId: 'HVfy.cLUSkmIobbLmPJFzQ',
    label: 'Photos de chantier',
    dbDocumentId: 'photo',
    owners: ['artisan'],
    reviewPhase: null,
    reviewPhaseOrder: null,
    requiredForCodesAdeme: [
      'BAR-EN-101',
      'BAR-EN-102',
      'BAR-EN-103',
      'BAR-TH-112',
      'BAR-TH-113',
      'BAR-TH-129',
      'BAR-TH-143',
      'BAR-TH-148',
      'BAR-TH-159',
      'BAR-TH-171',
      'BAR-TH-172',
    ],
  },
  {
    rowId: 'yEb4zyYGQCeXQeyX16fBxg',
    label: 'Timbre pour attestation (pré-payé)',
    dbDocumentId: 'timbre_attestation',
    owners: [],
    reviewPhase: null,
    reviewPhaseOrder: null,
    requiredForCodesAdeme: [],
  },
] as const;
